




















































import { appStore } from "@/app.store";
import { companiesStore } from "@/service/companies/companies.store";
import Vue from "vue";
import { api } from "@/service";

interface Param {
  type: 'path' | 'query'
  id: string
  name: string
  description?: string
  rules?: string
  value: string
}

interface Command {
  verb: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  path: string
  name: string
  description?: string
  params: Array<Param>
  body?: string
}

function expandCommandPath(command: Command): string {
  if (!command.params)
    return command.path;
  let path = command.path;
  command.params.forEach(x => {
    path = path.replace('{' + x.id + '}', x.value)
  })

  return path;
}

function getCommandQueryParams(command: Command): Record<string, any> | undefined {

  if (!command.params)
    return undefined;

  let params = Object.create(null);

  command.params
    .filter(x => x.type == 'query')
    .forEach(x => {
      params[x.id] = x.value
    })

  return params;
}

export default Vue.extend({
  data() {
    return {
      loading: false,
      selectedCommandIndex: undefined as undefined | number,
      commandResponse: '' as any
    };
  },
  computed: {
    commands(): Array<Command> {
      return [
        {
          verb: 'GET', name: 'Get a foo resource', description: 'Lorem ipsum', path: '/foo/{fooParam}', params: [
            { id: 'fooParam', type: 'path', name: 'ID', description: 'Lorem ipsum id', rules: 'required|min:5', value: '' },
            { id: 'bazParam', type: 'query', name: 'baz', description: 'Lorem ipsum baz', rules: '', value: 'prepopulated' },
            { id: 'companyId', type: 'query', name: 'Company ID', description: 'Lorem ipsum company', rules: 'required', value: companiesStore.state.currentId }
          ]
        },
        {
          verb: 'POST', name: 'Post a foo resource', description: 'Lorem ipsum', path: '/foo', params: [
            { id: 'fooParam', type: 'path', name: 'ID', description: 'Lorem ipsum id', rules: 'required|min:5', value: '' },
            { id: 'bazParam', type: 'query', name: 'baz', description: 'Lorem ipsum baz', rules: '', value: 'prepopulated' },
            { id: 'companyId', type: 'query', name: 'Company ID', description: 'Lorem ipsum company', rules: 'required', value: companiesStore.state.currentId }
          ],
          body: '{ "some": "json", "example": "of", "the" : "body" }'
        }
      ]
    },
    autoCompleteItems(): Array<{ text: string, value: any }> {
      return this.commands.map((x, index) => ({ text: x.name, value: index }))
    },
    selectedCommand(): Command | null {
      return this.selectedCommandIndex != undefined ? this.commands[this.selectedCommandIndex] : null
    }
  },
  methods: {
    async runCommand() {
      if (!companiesStore.state.currentId) return;
      if (!this.selectedCommand) return;

      const command = this.selectedCommand;
      try {
        this.loading = true;
        this.commandResponse = "";
        switch (command.verb) {
          case 'GET':
            this.commandResponse = await api.get(expandCommandPath(command), getCommandQueryParams(command));
            break;
          case 'POST':
            this.commandResponse = await api.post(expandCommandPath(command), command.body, getCommandQueryParams(command));
            break;
          case 'PUT':
            this.commandResponse = await api.put(expandCommandPath(command), command.body, getCommandQueryParams(command));
            break;
          case 'PATCH':
            this.commandResponse = await api.patch(expandCommandPath(command), command.body, getCommandQueryParams(command));
            break;
          case 'DELETE':
            this.commandResponse = await api.delete(expandCommandPath(command), getCommandQueryParams(command));
            break;
        }

        this.loading = false;
      } catch (error) {
        this.commandResponse = error;
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    }
  },
});
